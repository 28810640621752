import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js'
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { gsap } from 'gsap'

import Stats from 'three/examples/jsm/libs/stats.module'


htmlToElement = (html) => {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}
htmlToElements = (html) => {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
}

const categories = [
    {
        name: 'Obchody A-Z',
        slug: 'obchody',
        subcategories: [
            {
                name: 'Alza.cz 8,9',
                buildings: [8, 9],
            },
            {
                name: 'Asijský trh 29',
                buildings: [29],
            },
            {
                name: 'CDR-shop 28',
                buildings: [28],
            },
            {
                name: 'Cyklo centrum 19',
                buildings: [19],
            },
            {
                name: 'Doplňky do domácnosti 7',
                buildings: [7],
            },
            {
                name: 'Drogerie TETA 36',
                buildings: [36],
            },
            {
                name: 'Dobré ceny 29',
                buildings: [29],
            },
            {
                name: 'Fitness a výživa 18',
                buildings: [18],
            },
            {
                name: 'Koberce K+K 41',
                buildings: [41],
            },
            {
                name: 'Kulina – gastro shop 36',
                buildings: [36],
            },
            {
                name: 'Moravská banka vín – vinotéka 28',
                buildings: [28],
            },
            {
                name: 'Mountfield – zahrada/bazény 12',
                buildings: [12],
            },
            {
                name: 'Nabytkový Outlet 12',
                buildings: [12],
            },
            {
                name: 'Natu – zdravá výživa 29',
                buildings: [29],
            },
            {
                name: 'Norma – supermarket 11',
                buildings: [11],
            },
            {
                name: 'Obuv Trang 13',
                buildings: [13],
            },
            {
                name: 'Oděvy – Hanka Fashion 29',
                buildings: [29],
            },
            {
                name: 'Optika Brýles 29',
                buildings: [29],
            },
            {
                name: 'Olson/Apneaman – potápění 16',
                buildings: [16],
            },
            {
                name: 'Penny Market – supermarket 7',
                buildings: [7],
            },
            {
                name: 'Slovenské delikatesy/pekárna 22',
                buildings: [22],
            },
            {
                name: 'Tabák/Alkohol/Noviny 27',
                buildings: [27],
            },
            {
                name: 'Zavazadla a kabelky 13',
                buildings: [13],
            },
            {
                name: 'Železářství u Rotta 36',
                buildings: [36],
            },
        ],
    },
    {
        name: 'Kultura',
        slug: 'kultura',
        subcategories: [
            {
                name: 'Burza #4 4',
                buildings: [4],
            },
            {
                name: 'Jatka 78 7',
                buildings: [7],
            },
            {
                name: 'Rock Opera 10',
                buildings: [10],
            },
            {
                name: 'Sasazu Club 25',
                buildings: [25],
            },
            {
                name: 'Trafo Gallery 14',
                buildings: [14],
            },
        ],
    },
    {
        name: 'Zeleninový trh',
        slug: 'zeleninovy-trh',
        subcategories: [
            {
                name: 'Hala 22',
                buildings: [22],
            },
        ],
    },
    {
        name: 'Služby',
        slug: 'sluzby',
        subcategories: [
            {
                name: 'Automyčka AML 38',
                buildings: [38],
            },
            {
                name: 'Cykloservis 19',
                buildings: [19],
            },
            {
                name: 'Chefparade – škola vaření 36',
                buildings: [36],
            },
            {
                name: 'Knife Clinic – broušení nožů 22',
                buildings: [22],
            },
            {
                name: 'Perfect Storage – osobní sklady 12',
                buildings: [12],
            },
            {
                name: 'Pneu Centrum 39',
                buildings: [39],
            },
            {
                name: 'Šatník pro rodiny samoživitelů 13',
                buildings: [13],
            },
            {
                name: 'Oprava obuvi 35',
                buildings: [35],
            },
            {
                name: 'Veterinární laboratoř – Labvet 27',
                buildings: [27],
            },
            {
                name: 'Zámečnictví Maršál 22',
                buildings: [22],
            },
        ]
    },
    {
        name: 'Gastronomie',
        slug: 'gastronomie',
        subcategories: [
            {
                name: 'Hned vedle 2',
                buildings: [2],
            },
            {
                name: 'Bramborové taštičky 35',
                buildings: [35],
            },
            {
                name: 'Cukrárna Kopeček 29',
                buildings: [29],
            },
            {
                name: 'Food Truck Point 22–36',
                buildings: [0],
            },
            {
                name: 'Jatka78 – bistro 7',
                buildings: [7],
            },
            {
                name: 'Sasazu Restaurant 25',
                buildings: [25],
            },
            {
                name: 'TrangAn – Vietnamská restaurace 5',
                buildings: [5],
            },
            {
                name: 'Vrátnice – Vietnamské bistro 1',
                buildings: [1],
            },
            {
                name: 'Zumami Burger 25',
                buildings: [25],
            },
        ]
    },
    {
        name: 'Firmy',
        slug: 'firmy',
        subcategories: [
            {
                name: 'BOW TIE – agentura 19',
                buildings: [19],
            },
            {
                name: 'H40 Art & Digital Lab 40',
                buildings: [40],
            },
            {
                name: 'KPK – reklama a tisk 19',
                buildings: [19],
            },
            {
                name: 'NEXT LEVEL – agentura 19',
                buildings: [19],
            },
            {
                name: 'Prague Startup Market 24',
                buildings: [24],
            },
        ],
    },
    {
        name: 'Správa areálu',
        slug: 'sprava-arealu',
        subcategories: [
            {
                name: 'Sekretariát/Parkoviště/Security 5',
                buildings: [5]
            },
        ],
    }
]
const cats_container = document.getElementById('categories')
const cat_template = '<div id="category-{{cat_no}}" class="modal cat"><h1>{{cat_name}} <button type="button" class="close">Zavřít</button></h1></div>'
const subcat_template = '<div class="subcat"><a class="building-revealer" href="#building-{{building_id}}">{{subcat_name}}</a><div id="{{services_id}}"></div></div>'
Object.entries(categories).forEach(([i, category]) => {
    let cat_el = htmlToElement(cat_template.replaceAll('{{cat_no}}', category.slug).replaceAll('{{cat_name}}', `${category.name}`))
    // console.log(category)
    Object.entries(category.subcategories).forEach(([i, subcat]) => {
        let subcat_el = htmlToElement(subcat_template.replaceAll('{{subcat_name}}', subcat.name).replaceAll('{{building_id}}', subcat.buildings[0]).replaceAll('{{services_id}}', subcat.buildings))
        // console.log(subcat)
        cat_el.insertAdjacentElement('beforeend', subcat_el)
    })
    cats_container.insertAdjacentElement('beforeend', cat_el)
})

const objects = {
    1: {
        category: [
            {
                name: "GASTRONOMIE",
                service: [
                    "Vrátnice - Vietnam restaurace",
                ]
            }
        ]
    },
    2: {
        category: [
            {
                name: "GASTRONOMIE",
                service: [
                    "Hned vedle - kavárna",
                ]
            }
        ]
    },
    5: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Správa parkoviště",
                ]
            },
            {
                name: "GASTRONOMIE",
                service: [
                    "TrangAn - Vietnam restaurace",
                ]
            }
        ]
    },
    7: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Galanterie",
                    "Penny Market - supermarket",
                ]
            },
            {
                name: "DIVADLA / GALERIE",
                service: [
                    "Jatka 78 - divadlo",
                ]
            },
            {
                name: "GASTRONOMIE",
                service: [
                    "Jatka78 - bistro",
                ]
            },
        ]
    },
    8: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Alza a.s. - showroom / eshop",
                ]
            }
        ]
    },
    9: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Alza a.s. - showroom / eshop",
                ]
            }
        ]
    },
    10: {
        category: [
            {
                name: "DIVADLA / GALERIE",
                service: [
                    "Rock Opera - divadlo  ",
                ]
            }
        ]
    },
    11: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Norma - supermarket",
                ]
            }
        ]
    },
    12: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Perfect Storage - osobní sklady",
                ]
            },
            {
                name: "OBCHODY",
                service: [
                    "Mountfield - zahrada / bazény",
                    "Nábytkový Outlet",
                ]
            }
        ]
    },
    13: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Šatník - centrum pro rodinu",
                ]
            },
            {
                name: "OBCHODY",
                service: [
                    "Zavazadla a kabelky",
                ]
            },
            {
                name: "GASTRONOMIE",
                service: [
                    "Foodtruck Point",
                    "Zumami Burger",
                ]
            }
        ]
    },
    14: {
        category: [
            {
                name: "DIVADLA / GALERIE",
                service: [
                    "Trafo Gallery - galerie",
                ]
            }
        ]
    },
    16: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Apneaman - freediving",
                    "Olson - potápění",
                ]
            }
        ]
    },
    17: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Obuv Trang",
                ]
            }
        ]
    },
    18: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Fitness a výživa",
                    "Papelote",
                ]
            }
        ]
    },
    19: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Cyklo servis",
                ]
            },
            {
                name: "OBCHODY",
                service: [
                    "Cyklo Centrum",
                ]
            },
            {
                name: "FIRMY",
                service: [
                    "BOW TIE - agentura",
                    "KPK - reklama a tisk",
                    "NEXT LEVEL- agentura",
                ]
            }
        ]
    },
    22: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Knife Clinic - nožíř",
                    "Zámečnictví Maršál",
                ]
            },
            {
                name: "OBCHODY",
                service: [
                    "Slovenské delikatesy / pekárna",
                ]
            },
            {
                name: "ZELENINOVÝ TRH",
                service: [
                    "Hala22",
                ]
            }
        ]
    },
    24: {
        category: [
            {
                name: "FIRMY",
                service: [
                    "Prague Startup Market",
                ]
            }
        ]
    },
    25: {
        category: [
            {
                name: "DIVADLA / GALERIE",
                service: [
                    "Sasazu Club",
                ]
            },
            {
                name: "GASTRONOMIE",
                service: [
                    "Sasazu Restaurant",
                ]
            }
        ]
    },
    26: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Alza - servisní středisko",
                ]
            }
        ]
    },
    27: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Veterinární laboratoř - Labvet",
                ]
            },
            {
                name: "OBCHODY",
                service: [
                    "Tabák - alkohol / noviny",
                ]
            }
        ]
    },
    28: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Elektro CDR-shop",
                    "Moravská banka vín - vinotéka",
                ]
            }
        ]
    },
    29: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Natu - přírodní produkty",
                    "Oděvy - Hanka Fashion",
                    "Optika Brýles",
                    "Váš levný nákup",
                ]
            },
            {
                name: "GASTRONOMIE",
                service: [
                    "Cukrárna Kopeček",
                ]
            }
        ]
    },
    31: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Pneu Centrum",
                ]
            }
        ]
    },
    35: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Opravna obuvi",
                ]
            },
            {
                name: "GASTRONOMIE",
                service: [
                    "Bramborové taštičky",
                ]
            }
        ]
    },
    36: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Chefparade - škola vaření",
                ]
            },
            {
            name: "OBCHODY",
                service: [
                    "Drogerie TETA",
                    "Kulina - gastro shop",
                    "Železářství u Rotta",
                ]
            }
        ]
    },
    38: {
        category: [
            {
                name: "SLUŽBY",
                service: [
                    "Automyčka AML",
                ]
            }
        ]
    },
    40: {
        category: [
            {
                name: "FIRMY",
                service: [
                    "HALA 40",
                ]
            }
        ]
    },
    41: {
        category: [
            {
                name: "OBCHODY",
                service: [
                    "Koberce K+K",
                ]
            }
        ]
    }
}
const objects_container = document.getElementById('objects')
const object_template = '<div id="object-{{object_no}}" class="object"><h1>{{object_name}}</h1></div>'
const category_template = '<div class="category"><h2>{{category_name}}</h2><div id="{{services_id}}"></div></div>'
const services_template = '<div class="service"><h3>{{service}}</h3<ul></ul></div>'

Object.entries(objects).forEach(([object_no, object_data]) => {
    let object_el = htmlToElement(object_template.replaceAll('{{object_no}}', object_no).replaceAll('{{object_name}}', `Budova č. ${object_no}`))
    let services_id = `object-${object_no}-services`

    let categories = object_data.category

    Object.entries(categories).forEach(([i, category]) => {
        let category_el = htmlToElement(category_template.replaceAll('{{category_name}}', category.name).replaceAll('{{services_id}}', services_id))
        let services_container = category_el.querySelector(`#${services_id}`)
        Object.entries(category.service).forEach(([i, service]) => {
            let service_el = htmlToElement(services_template.replaceAll('{{service}}', service))
            services_container.insertAdjacentElement('beforeend', service_el)
        })
        object_el.insertAdjacentElement('beforeend', category_el)
    })
    objects_container.insertAdjacentElement('beforeend', object_el)
})






















const params = {
	bgcolor: 0x161616,
}

const scene = new THREE.Scene()
scene.background = new THREE.Color( params.bgcolor );
scene.add(new THREE.AxesHelper(5))

const light = new THREE.PointLight()
light.position.set(0, 1, 0)
light.castShadow = true
// scene.add(light)



const ambientLight = new THREE.AmbientLight( 0x888888 )
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 );
directionalLight.position.set(200, 800, -200)
directionalLight.castShadow = true
scene.add( directionalLight );
directionalLight.shadow.mapSize.width = 512; // default
directionalLight.shadow.mapSize.height = 512; // default
directionalLight.shadow.camera.near = 0.5; // default
directionalLight.shadow.camera.far = 5000; // default
directionalLight.shadow.camera.left = 200; // default
directionalLight.shadow.camera.right = -200; // default
directionalLight.shadow.camera.top = -200; // default
directionalLight.shadow.camera.bottom = 200; // default

const hemisphereLight = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );
//scene.add( hemisphereLight );

const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    100000
)
const helper = new THREE.CameraHelper( directionalLight.shadow.camera );
scene.add( helper );

const camera_pos = {
    x: 250,
    y: 687.5,
    z: 375
}

camera.position.set(camera_pos.x, camera_pos.y, camera_pos.z)
camera.zoom = 2



// const w = window.innerWidth;
// const h = window.innerHeight;
// const viewSize = h;
// const aspectRatio = w / h;

// const viewport = {
//     viewSize: viewSize,
//     aspectRatio: aspectRatio,
//     left: (-aspectRatio * viewSize) / 2,
//     right: (aspectRatio * viewSize) / 2,
//     top: viewSize / 2,
//     bottom: -viewSize / 2,
//     near: -1000,
//     far: 1000
// }

// const camera = new THREE.OrthographicCamera ( 
//     viewport.left, 
//     viewport.right, 
//     viewport.top, 
//     viewport.bottom, 
//     viewport.near, 
//     viewport.far 
// );
// camera.position.set(0, 0, 0)
// camera.zoom = 2.5

camera.updateProjectionMatrix();

document.body.addEventListener('click', () => {
    //console.log(camera);
});

const renderer = new THREE.WebGLRenderer()
renderer.setSize(window.innerWidth, window.innerHeight)
renderer.setPixelRatio(window.devicePixelRatio)
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

document.body.appendChild(renderer.domElement)

labelsScene = new THREE.Scene()
const material = new THREE.MeshBasicMaterial( { color: 0x000000, wireframe: false, wireframeLinewidth: 1, transparent: true, opacity: 0, side: THREE.DoubleSide } );
const labelData = [
    {
        name: 'Hlavní náměstí',
        coords: [
            {x: -150, y: 55, r: 0}
        ]
    },
    {
        name: 'Západní korzo',
        coords: [
            {x: -85, y: 30, r: 90}
        ]
    },
    {
        name: 'Východní korzo',
        coords: [
            {x: -40, y: 30, r: 90}
        ]
    },
    {
        name: 'Argentinská',
        coords: [
            {x: -250, y: 100, r: 90},
            {x: -250, y: 0, r: 90},
            {x: -250, y: -100, r: 90},
        ]
    },
    {
        name: 'Bubenské nábřeží',
        coords: [
            {x: -100, y: 140, r: 0},
            {x: 60, y: 130, r: 20},
            {x: 170, y: 95, r: 20},
        ]
    },
    {
        name: 'Komunardů',
        coords: [
            {x: 225, y: -60, r: 95},
            {x: 230, y: 30, r: 95},
        ]
    },
    {
        name: 'Jateční',
        coords: [
            {x: -200, y: -140, r: 0},
            {x: -100, y: -140, r: 0},
            {x: 0, y: -140, r: 0},
            {x: 100, y: -140, r: 0},
            {x: 200, y: -140, r: 0},
        ]
    },
]

const parkings = [
    {
        slug: 0,
        entryPoints: [
            {
                x: 0,
                y: 0,
            },
        ],
    },
]

const socials = [
    {
        building: 0,
        entryPoints: [
            {
                x: 0,
                y: 0,
            },
        ],
    },
]

const atms = [
    {
        building: 0,
        entryPoints: [
            {
                x: 0,
                y: 0,
            },
        ],
    },
]

const amenities = {

}

const userCam = true
const controls = new OrbitControls(camera, renderer.domElement)
controls.enabled = userCam
controls.enableDamping = true
controls.target.set(0, 1, 0)

//const material = new THREE.MeshNormalMaterial()

const city = () => new URL( "../assets/models/city.fbx", import.meta.url ).toString()
const building = () => new URL( "../assets/textures/building.jpg", import.meta.url ).toString()

let currentObject = null
const allObjects = []
const intersectObjects = []
const intersectLabels = []
const buildings = {}

let tempV = new THREE.Vector3()
let labels = []
const labels_container = document.getElementById('labels')
const labelsRaycaster = new THREE.Raycaster();

const fbxLoader = new FBXLoader()
const textureLoader = new THREE.TextureLoader()
const enteranceData = []
fbxLoader.load(
    city(),
    (object) => {
        const box = new THREE.Box3().setFromObject( object );
		const center = box.getCenter( new THREE.Vector3() );

        // object.scale.set(0.01, 0.01, 0.01)

		// object.position.x += ( object.position.x - center.x );
		// object.position.y += ( object.position.y - center.y );
		// object.position.z += ( object.position.z - center.z );
        object.traverse(function (child) {
            if ( child.isMesh ) {
                allObjects.push(child)

                let data = {};
                if ( child.name.indexOf('Budova') != -1 ) {
                    data.label = 'Budova'
                    data.no    = child.name.replace(data.label, '')
                    data.type  = 'object'
                    child.buildingData = data
                    intersectObjects.push(child)
                    intersectLabels.push(child)
                    buildings[data.no] = child

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.FrontSide,
                            color: new THREE.Color( 0xE7E7E7 ),
                            
                            metalness: 0.6, // 0.4,
                            roughness: 0, // 0.4,
                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        // child.geometry.deleteAttribute('normal')
                        // child.geometry.deleteAttribute('uv')
                        // child.geometry = mergeVertices(child.geometry)
                        child.geometry.computeVertexNormals();
                        child.castShadow = true //default is false
                        child.receiveShadow = false //default
                        // console.log(child)

                        // child.material.color = new THREE.Color( 0xE7E7E7 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('ATM') != -1 ) {
                    data.label = 'Bankomat'
                    data.no    = child.name.replace('ATM', '')
                    data.type  = 'object'
                    child.atmData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('Entrance') != -1 ) {
                    data.label = 'Vstup do budovy'
                    data.no    = child.name.replace('Entrance', '')
                    data.type  = 'object'
                    child.enteranceData = data
                    // intersectLabels.push(child)
                    enteranceData.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('Parking') != -1 ) {
                    data.label = 'Parking'
                    data.no    = child.name.replace('Parking', '')
                    data.type  = 'object'
                    child.parkingData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('InCar') != -1 ) {
                    data.label = 'Vjezd'
                    data.no    = child.name.replace('InCar', '')
                    data.type  = 'object'
                    child.inCarData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('OutCar') != -1 ) {
                    data.label = 'Výjezd'
                    data.no    = child.name.replace('OutCar', '')
                    data.type  = 'object'
                    child.outCarData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('InOutWalk') != -1 ) {
                    data.label = 'Vchod a východ'
                    data.no    = child.name.replace('InOutWalk', '')
                    data.type  = 'object'
                    child.inOutWalkData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('WC') != -1 ) {
                    data.label = 'WC'
                    data.no    = child.name.replace(data.label, '')
                    data.type  = 'object'
                    child.wcData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('Ferry') != -1 ) {
                    data.label = 'Přívoz'
                    data.no    = child.name.replace('Ferry', '')
                    data.type  = 'object'
                    child.ferryData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else if ( child.name.indexOf('Tram') != -1 ) {
                    data.label = 'Tramvaj'
                    data.no    = child.name.replace('Tram', '')
                    data.type  = 'object'
                    child.tramData = data
                    intersectLabels.push(child)

                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),
                            opacity: 0,
                            transparent: true,

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                } else {
                    child.buildingData = false;
                    if (child.isMesh) {
                        const oldMat = child.material;
                        child.material = new THREE.MeshStandardMaterial( {//MeshMatcapMaterial( {  
                            side: THREE.DoubleSide,
                            color: new THREE.Color( 0x161616 ),

                            // matcap: textureLoader.load( building() )
                            //map: oldMat.map,
                        } );
                        child.castShadow = false //default is false
                        child.receiveShadow = true //default

                        // child.material.color = new THREE.Color( 0x161616 )
                        // child.material.emissive = new THREE.Color( 0x000000 )
                        // child.material.transparent = true
                    }
                    return 1;
                }

                let label = document.createElement('div')
                if ( child.inOutWalkData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-vchod-a-vychod"></use></svg>`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.parkingData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-parkoviste"></use></svg>`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.atmData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-bankomat"></use></svg>`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.wcData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-wc"></use></svg>`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.ferryData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-privoz"></use></svg>`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.tramData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-tramvaj"></use></svg>`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.inCarData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-vjezd"></use></svg>`
                    let svg = label.querySelector('svg')
                    svg.style.transform = `rotate(${child.rotation.z*180/Math.PI}deg)`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else if ( child.outCarData !== undefined ) {
                    label.classList.add('icon')
                    label.innerHTML = `<svg viewBox="0 0 18 18"><title>${data.label} č. ${data.no}</title><use href="#svg-vyjezd"></use></svg>`
                    let svg = label.querySelector('svg')
                    svg.style.transform = `rotate(${child.rotation.z*180/Math.PI}deg)`
                    labels_container.appendChild(label)
                    labels.push({child, label})
                } else {
                    label.textContent = `${data.label} č. ${data.no}`
                    labels_container.appendChild(label)
                    //labels.push({child, label})
                }
                

                child.mouseddown = () => {
                    let object = document.getElementById(`${data.type}-${data.no}`);
                    if ( object == currentObject ) {
                        currentObject = null

                        allObjects.forEach((o) => {
                            gsap.to(o.material, {
                                opacity: 1,
                                duration: .5,
                            })
                        })
                        
                        gsap.to(controls.target, {
                            x: 0,
                            y: 1,
                            z: 0,
                            duration: .75,
                            onUpdate: () => {
                                controls.update();
                            }
                        })
                        gsap.to(camera, {
                            zoom: 2,
                            duration: 1.5,
                            onUpdate: () => {
                                camera.updateProjectionMatrix();
                            }
                        })
                        document.body.classList.remove('content-open')
                        object.classList.remove('active');
                    } else {
                        if ( currentObject ) {
                            currentObject.classList.remove('active')
                        }
                        currentObject = object

                        allObjects.forEach((o) => {
                            if ( o.ID != child.ID ) {
                                gsap.to(o.material, {
                                    opacity: 0.1,
                                    duration: .75,
                                })
                            } else {
                                gsap.to(child.material, {
                                    opacity: 1,
                                    duration: 1.5,
                                })
                            }
                        })
                        
                        gsap.to(controls.target, {
                            ...child.position,
                            duration: 1.5,
                            onUpdate: () => {
                                controls.update();
                            }
                        })
                        gsap.to(camera, {
                            // zoom: 4,
                            duration: 3,
                            onUpdate: () => {
                                camera.updateProjectionMatrix();
                            }
                        })
                        document.body.classList.add('content-open')
                        object.classList.add('active');
                    }
                }
            }
            // if (child.isMesh) {
            //     // (child as THREE.Mesh).material = material
            //     if (child.material) {
            //         child.material.transparent = true
            //     }
            // }
            
        })

        scene.add(object)

        loadLabels()
    },
    (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
    },
    (error) => {
        console.log(error)
    }
)

const labelsRenderer = new CSS3DRenderer()
loadLabels = () => {
    labelData.forEach((data) => {
        data.coords.forEach((coord) => {
            const element = document.createElement( 'div' )
            element.textContent = data.name
            element.style.width = '1000px'
            element.style.height = '250px'
            element.style.lineHeight = '250px'
            element.style.fontSize = '70px'
            element.style.color = '#E7E7E7'
            element.style.textAlign = 'center'
            element.style.opacity = 1
            element.style.background = 'transparent' //new THREE.Color( Math.random() * 0xffffff ).getStyle()
            element.style.pointerEvents = 'none'
            element.style.backfaceVisibility = 'hidden'

            const object = new CSS3DObject( element );
            object.element.style.pointerEvents = 'none'
            object.position.x = coord.x
            object.position.y = 1
            object.position.z = coord.y
            object.rotation.x = -90 * Math.PI / 180
            object.rotation.y = 0 * Math.PI / 180
            object.rotation.z = (coord.r-2) * Math.PI / 180
            object.scale.x = .1;
            object.scale.y = .1;
            labelsScene.add( object );

            const geometry = new THREE.PlaneGeometry( 100, 100 );
            const mesh = new THREE.Mesh( geometry, material );
            mesh.position.copy( object.position );
            mesh.rotation.copy( object.rotation );
            mesh.scale.copy( object.scale );
            scene.add( mesh );
        })
    })
    enteranceData.forEach((entrance) => {
        console.log(entrance)
        let data = entrance.enteranceData
        console.log(data)
        const element = document.createElement( 'div' )
        element.innerHTML = '<svg viewBox="0 0 18 18"><title>'+data.label+'</title><use href="#svg-vstup"></use></svg>'
        element.style.width = '100px'
        element.style.height = '100px'
        element.style.lineHeight = '100px'
        element.style.fontSize = '100px'
        element.style.color = '#E7E7E7'
        element.style.textAlign = 'center'
        element.style.opacity = 1
        element.style.background = 'transparent' //new THREE.Color( Math.random() * 0xffffff ).getStyle()
        element.style.pointerEvents = 'none'
        element.style.backfaceVisibility = 'hidden'

        const object = new CSS3DObject( element );
        object.element.style.pointerEvents = 'none'
        object.position.x = entrance.position.x
        object.position.y = entrance.position.y
        object.position.z = entrance.position.z
        object.rotation.x = -90 * Math.PI / 180
        object.rotation.y = 0 * Math.PI / 180
        object.rotation.z = entrance.rotation.z//(entrance.rotation.x-2) * Math.PI / 180
        object.scale.x = .1;
        object.scale.y = .1;
        labelsScene.add( object );

        const geometry = new THREE.PlaneGeometry( 100, 100 );
        const mesh = new THREE.Mesh( geometry, material );
        mesh.position.copy( object.position );
        mesh.rotation.copy( object.rotation );
        mesh.scale.copy( object.scale );
        scene.add( mesh );
    })

    labelsRenderer.setSize(window.innerWidth, window.innerHeight)
    labelsRenderer.domElement.style.position = 'absolute'
    labelsRenderer.domElement.style.top = 0
    labelsRenderer.domElement.style.pointerEvents = 'none'
    document.body.appendChild( labelsRenderer.domElement )
}

const pointerRaycaster = new THREE.Raycaster();

window.addEventListener('resize', onWindowResize, false)
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
    labelsRenderer.setSize(window.innerWidth, window.innerHeight)
    render()
}

let INTERSECTED;

const pointer = new THREE.Vector2();
document.addEventListener( 'mousemove', onMouseMove );
function onMouseMove( event ) {
    pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
}
document.addEventListener( 'click', onMouseDown );
function onMouseDown( event ) {
    event.preventDefault();

    pointer.x = ( event.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( event.clientY / renderer.domElement.clientHeight ) * 2 + 1;

    pointerRaycaster.setFromCamera( pointer, camera );

    var intersects = pointerRaycaster.intersectObjects( intersectObjects ); 

    if ( intersects.length > 0 ) {
        intersects[0].object.mouseddown();

    }

}


const stats = Stats()
stats.dom.style.top = 'auto';
stats.dom.style.bottom = 0;
document.body.appendChild(stats.dom)

function animate() {
    requestAnimationFrame(animate)

    controls.update()

    render()

    stats.update()
}

let camIsTweening = false
let intersects

function render() {
    labels.forEach((label_obj, i) => {
        const {child, label} = label_obj;
        // get the position of the center of the child
        child.updateWorldMatrix(true, false);
        child.getWorldPosition(tempV);

        // get the normalized screen coordinate of that position
        // x and y will be in the -1 to +1 range with x = -1 being
        // on the left and y = -1 being on the bottom
        tempV.project(camera);

        // ask the raycaster for all the objects that intersect
        // from the eye toward this object's position
        labelsRaycaster.setFromCamera(tempV, camera);
        const labelsIntersects = labelsRaycaster.intersectObjects(intersectLabels)
        // We're visible if the first intersection is this object.
        const show = labelsIntersects.length && child === labelsIntersects[0].object;
        if (!show) {
            // hide the label
            label.style.display = 'none';
        } else {
            // unhide the label
            label.style.display = '';

            // convert the normalized position to CSS coordinates
            const x = (tempV.x *  .5 + .5) * renderer.domElement.clientWidth;
            const y = (tempV.y * -.5 + .5) * renderer.domElement.clientHeight;

            // move the label to that position
            label.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
        }
    })

    pointerRaycaster.setFromCamera( pointer, camera );
    if ( intersectObjects ) {
        intersects = pointerRaycaster.intersectObjects( intersectObjects, false );
        if ( intersects.length > 0 ) {
            if ( INTERSECTED != intersects[ 0 ].object ) {
                if ( INTERSECTED ) {
                    INTERSECTED.material.color.setHex( INTERSECTED.currentHex );
                }
                INTERSECTED = intersects[ 0 ].object
                // directionalLight.target = INTERSECTED
                INTERSECTED.currentHex = INTERSECTED.material.color.getHex()
                INTERSECTED.material.color.setHex( 0xE16743 )
            }

        } else {

            if ( INTERSECTED ) {
                INTERSECTED.material.color.setHex( INTERSECTED.currentHex )
            }

            INTERSECTED = null;

        }
    }

    if ( !camIsTweening && !userCam ) {
        gsap.to(camera.position, {
            x: camera_pos.x + (pointer.x * 10),
            y: camera_pos.y + (pointer.y * 10),
            duration: 1,
            onComplete: () => {
                camIsTweening = false
            }
        })
        //camera.updateProjectionMatrix();
    }



    renderer.render(scene, camera)
    labelsRenderer.render(labelsScene, camera)
}

animate()


















const modals_container = document.getElementById('modals')
const modals = modals_container.querySelectorAll('.modal')
const modal_openers = document.querySelectorAll('.modal-opener')
const modal_closers = modals_container.querySelectorAll('.close')
console.log(modals_container)
console.log(modal_closers)
modal_openers.forEach((opener) => {
    opener.addEventListener('click', (e) => {
        e.preventDefault()
        modals_container.querySelectorAll('.modal.active').forEach((e) => e.classList.remove('active'))
        let active = document.querySelector(opener.hash)
        active.classList.add('active')
        modals_container.classList.add('open')
    })
})
modal_closers.forEach((closer) => {
    closer.addEventListener('click', (e) => {
        e.preventDefault()
        modals_container.querySelectorAll('.modal.active').forEach((e) => e.classList.remove('active'))
        modals_container.classList.remove('open')

        gsap.to(controls.target, {
            x: 0,
            y: 1,
            z: 0,
            duration: .75,
            onUpdate: () => {
                controls.update();
            }
        })
        gsap.to(camera, {
            zoom: 2,
            duration: 1.5,
            onUpdate: () => {
                camera.updateProjectionMatrix();
            }
        })
    })
})

const building_revealers = document.querySelectorAll('.building-revealer')
const selected_building = document.getElementById('selected-building')
const selected_building_info = selected_building.querySelector('.text')
const selected_closers = selected_building.querySelectorAll('.close')

let active_building = null
building_revealers.forEach((revealer) => {
    revealer.addEventListener('click', (e) => {
        e.preventDefault()
        let building_no = e.target.hash.replace('#building-', '')
        let building = buildings[building_no]

        selected_building.classList.add('active')
        selected_building_info.innerText = e.target.innerText
        modals_container.querySelectorAll('.modal.active').forEach((e) => e.classList.remove('active'))
        modals_container.classList.remove('open')

        if ( active_building ) {
            active_building.material.color.setHex( 0xf7f7f7 )
        }
        building.material.color.setHex( 0xE16743 )
        active_building = building

        gsap.to(controls.target, {
            ...building.position,
            duration: 1.5,
            onUpdate: () => {
                controls.update();
            }
        })
        gsap.to(camera, {
            zoom: 2,
            duration: 3,
            onUpdate: () => {
                camera.updateProjectionMatrix();
            }
        })
    })
})

selected_closers.forEach((closer) => {
    closer.addEventListener('click', (e) => {
        e.preventDefault()
        selected_building.classList.remove('active')

        if ( active_building ) {
            active_building.material.color.setHex( 0xf7f7f7 )
            active_building = null
        }

        gsap.to(controls.target, {
            x: 0,
            y: 1,
            z: 0,
            duration: .75,
            onUpdate: () => {
                controls.update();
            }
        })
        gsap.to(camera, {
            zoom: 2,
            duration: 1.5,
            onUpdate: () => {
                camera.updateProjectionMatrix();
            }
        })
    })
})